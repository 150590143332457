import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Stack } from "@mui/material";

import { number, oneOf, string } from "prop-types";

import useSWR from 'swr';

import { fetcher } from "../../utils/fetcher";

import MenuItems from "./MenuItems";
import Loader from "../Loader";
import { getTranslation } from "../../utils/translate";

function Menu({ id, variant, limit=3, extraMenuItems=[], title }) {
    const [menuItems, setMenuItems] = useState([]);

    const { code:languageCode } = useSelector(state => state.language);
    
    const apiFieldsToFetch = [
        '*.*',
        'name',
        'menu_items.*',
        'menu_items.page.*',
        'menu_items.translations.image',
        'menu_items.translations.label',
        'menu_items.translations.languages_code',
    ]

    const { data, error, isLoading:loading } = useSWR({ url: `menus/${id}`, fields: apiFieldsToFetch }, fetcher);

    useEffect(async() => {
        setMenuItems(
            [
                ...data?.menu_items?.map(menuItem => {
                    const translatedMenuItem = getTranslation(menuItem, { key: 'languages_code', code: languageCode });

                    return {
                        ...menuItem,
                        label: translatedMenuItem?.label,
                        image: translatedMenuItem?.image,
                    }
                }) || [],
            ]
                .filter(menuItem => menuItem.status === 'published')
                .sort((a, b) => (a.sort - b.sort))
        )
    }, [data, languageCode]);

    const showFetchingStatus = (isLoading) => {
        if (isLoading) {
            return <Loader compact />
        }
    }

    if (error) {
        console.log('error: ', error);
    }

    return (
        <Stack
            spacing={3}
        >
            {variant !== 'inline' && showFetchingStatus(loading) }

            {
                !!menuItems?.length && 
                <MenuItems
                    menuItems={[
                        ...extraMenuItems,
                        ...menuItems,
                        
                    ]}
                    variant={variant}
                    limit={limit}
                    title={title}
                />
            }
        </Stack>
    )
}

Menu.propTypes = {
    id: string.isRequired,
    variant: oneOf(['default', 'inline', 'fullWidth', 'list']),
    limit: number,
}

export default Menu