import { useParams } from 'react-router-dom';

import { bool, node } from "prop-types";
import useSWR from "swr";

import { fetcher } from "../../utils/fetcher";

import Header from "../../components/Header";
import BackButton from "../../components/BackButton";
import PageLogo from "../../components/PageLogo";
import Footer from "../../components/Footer";
import { Box } from '@mui/material';

const PageLayout = ({children, showLogo=true, }) => {
    const params = useParams();
    const retailerId = params.retailerId;

    const retailerFieldsToFetch = [
        'logo',
    ];

    const { data: retailerSettings } = useSWR(retailerId ? { url: `/retailer/${retailerId}`, fields: retailerFieldsToFetch } : null, fetcher);

    console.log('retailerSettings', retailerSettings);
    console.log('retailerId', retailerId);

    return (
        <>
            <Box
                sx={{
                    minHeight: 'calc(100vh - 320px)',
                }}
            >
                <Header />
                <BackButton />
                {showLogo && <PageLogo logoOverrideId={retailerSettings?.logo} />}
                {children}
            </Box>
            <Footer />
        </>
    )
}

PageLayout.propTypes = {
    children: node.isRequired,
    showLogo: bool
}

export default PageLayout;