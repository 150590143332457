import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { arrayOf, bool, oneOf, shape, string } from "prop-types";

import { Avatar, Box, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from "@mui/material";

import DashboardCard from "../../DashboardCard";
import FullWidthCard from "../../FullWidthCard";
import { useRetailerSettings } from "../../../hooks/useRetailerSettings";
import { KeyboardArrowRight } from "@mui/icons-material";

const InlineLinkText = ({ label='' }) => {
    return (
        <Typography
            variant='span'
            sx={{
                color: '#ffffff'
            }}
        >
            {label}
        </Typography>
    );
};

const ListLinkContent = ({ label, image }) => {
    return (
        <ListItem
            secondaryAction={
                <IconButton
                    edge="end"
                    aria-label="arrow"
                >
                    <KeyboardArrowRight />
                </IconButton>
            }

        >
            {
                !!image &&
                <ListItemAvatar>
                    <Avatar
                        alt={label}
                        src={`${process.env.REACT_APP_API_URL}/assets/${image}?key=avatar`}
                    />
                </ListItemAvatar>
            }

            <ListItemText
                primary={label}
                sx={{
                    color: '#000',
                }}
            />
        </ListItem>
    );
};

function MenuItems({ menuItems, variant = 'default', title }) {
    const { code:languageCode } = useSelector(state => state.language);
    const { retailerSettings } = useRetailerSettings();

    const [formattedMenuItems, setFormattedMenuItems] = useState([]);

    const buildUrl = (collection, itemId) => {
        // these collections have names that match the url
        const collectionsThatDoNotNeedSpecialUrls = ['collections', 'recipe'];

        if (collectionsThatDoNotNeedSpecialUrls.includes(collection)) {
            return `/${collection}/${itemId}`;
        } else if (collection === 'beef_product') {
            return `/recipe-list?beef_cut=${itemId}`;
        } else if (collection === 'pages') {
            return `/article/${itemId}`;
        } else if (collection === 'categories') {
            return `/articles/category/${itemId}`;
        } else {
            return '/';
        }
    }

    useEffect(() => {
        const parsedMenuItems = menuItems.map(menuItem => {
            let isExternalLink = true;
            let url = menuItem.url;
            const image = menuItem.image

            // handle internal links to application pages
            if (url?.startsWith('/')) {
                isExternalLink = false;
            }
            
            // handle internal links to content pages
            if (menuItem?.page?.length) {
                const item = menuItem.page[0];
                url = {
                    pathname: buildUrl(item.collection, item.item),
                    state: {
                        from: `${location.pathname}${location.search}`
                    }
                }

                isExternalLink = false;
            }

            return {
                ...menuItem,
                externalLink: isExternalLink,
                url,
                image,
            }
        });

        setFormattedMenuItems(parsedMenuItems);
    }, [menuItems])

    return (
        <Box>
            {
                variant === 'list' &&
                <Box>
                    {
                        !!title &&
                        <Typography
                            sx={{
                                mb: 1,
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                fontSize: '0.8rem',
                                textAlign: 'center',
                            }}
                        >
                            {title}
                        </Typography>
                    }

                    <List>
                        {
                            formattedMenuItems?.map((menuItem, index) => (
                                <Fragment
                                    key={menuItem.id}
                                >
                                    {
                                        menuItem.externalLink &&
                                        <a href={menuItem.url}>
                                            <ListLinkContent
                                                label={menuItem.label}
                                                image={menuItem.image}
                                            />
                                        </a>
                                    }

                                    {
                                        !menuItem.externalLink &&
                                        <Link to={menuItem.url}>
                                            <ListLinkContent
                                                label={menuItem.label}
                                                image={menuItem.image}
                                            />
                                        </Link>
                                    }

                                    {
                                        !(index === formattedMenuItems.length - 1) &&
                                        <Divider
                                            component={'li'}
                                        />
                                    }
                                </Fragment>
                            ))
                        }
                    </List>
                </Box>
            }

            {
                variant === 'inline' &&
                <Box>
                    <Stack
                        className='no-print'
                        sx={{
                            width: '100%',
                            py: 4
                        }}
                        spacing={1}
                        direction={{
                            xs: 'column',
                            md: 'row',
                        }}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        {
                            formattedMenuItems?.map((menuItem, index) => (
                                <Fragment key={menuItem.id}>
                                    <Box>
                                        { menuItem.externalLink &&
                                            <a href={menuItem.url}>
                                                <InlineLinkText
                                                    label={menuItem.label}
                                                />
                                            </a>
                                        }

                                        { !menuItem.externalLink &&
                                            <Link to={menuItem.url}>
                                                <InlineLinkText
                                                    label={menuItem.label}
                                                />
                                            </Link>
                                        }
                                    </Box>
                                    
                                    {
                                        !(index === formattedMenuItems.length - 1) &&
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                            sx={{
                                                borderColor: retailerSettings?.brand_color || '#fff'
                                            }}
                                        />
                                    }
                                </Fragment>
                            ))
                        }
                    </Stack>
                </Box>
            }

            {
                variant === 'default' &&
                <Grid
                    container
                    spacing={{
                        xs: 2,
                        md: 4
                    }}
                >
                    {
                        formattedMenuItems?.map(menuItem => (
                            <Grid
                                key={menuItem.id}
                                item
                                xs={6}
                            >
                                <DashboardCard
                                    item={{
                                        label: menuItem.label,
                                        goTo: menuItem.url,
                                        svg_icon: menuItem.image,
                                        enlargeImg: true,
                                        externalLink: menuItem.externalLink
                                    }}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            }

            {
                variant === 'fullWidth' &&
                <Box>
                    {
                        formattedMenuItems?.map(menuItem => (
                            <Box
                                key={menuItem.id}
                            >
                                <FullWidthCard
                                    url={menuItem.url}
                                    label={menuItem.label}
                                    externalLink={menuItem.externalLink}
                                    image={
                                        menuItem.image ?
                                        `${process.env.REACT_APP_API_URL}/assets/${menuItem.image}?key=full-width-card` :
                                        null
                                    }
                                />
                            </Box>
                        ))
                    }
                </Box>
            }
        </Box>
    )
}

MenuItems.propTypes = {
    menuItems: arrayOf(
        shape({
            id: string.isRequired,
            url: string,
            image: string,
            label: string.isRequired,
            externalLink: bool,
        })
    ).isRequired,
    variant: oneOf(['default', 'inline', 'fullWidth']),
}

export default MenuItems